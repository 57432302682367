import React from 'react'
import banner2 from "../../../static/img/afisa.pdf";


const BannerPage = () => (
    
<div className='container'>
<iframe style={{ minWidth: "80%", height:"80vh" }} src="/img/afisa.pdf" target="_blank"> 
</iframe>
</div>
)

export default BannerPage
